<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-2">Experiment 3 - Pre-Lab Exercise: Part I</h2>

      <p class="mb-2">
        a) If the air temperature in your experiment was measured at
        <number-value :value="temp1" unit="^\circ\text{C}" />
        and the barometric pressure was given as
        <number-value :value="pressure1" unit="\text{mmHg,}" />
        what volume of
        <chemical-latex content="H2(g)" />
        (in mL) would you expect to produce if you reacted
        <number-value :value="massCa1" unit="\text{g}" />
        with an excess of HCl? Assume that the vapor pressure of water is negligible in this
        question.
      </p>

      <calculation-input
        v-model="inputs.volumeH2"
        prepend-text="$\text{Volume:}$"
        append-text="$\text{mL}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) If you weighed out
        <number-value :value="massCa2" unit="\text{g}" />
        and reacted it with an excess of
        <chemical-latex content="HCl," />
        how many moles of
        <chemical-latex content="H2(g)" />
        would you expect to produce?
      </p>

      <calculation-input
        v-model="inputs.molH2"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        class="mb-0"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'OleMiss116Lab1Prelab',
  components: {NumberValue, ChemicalNotationInput, ChemicalLatex, CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        volumeH2: null,
        molH2: null,
      },
    };
  },
  computed: {
    temp1() {
      return this.taskState.getValueBySymbol('temp1').content;
    },
    pressure1() {
      return this.taskState.getValueBySymbol('pressure1').content;
    },
    massCa1() {
      return this.taskState.getValueBySymbol('massCa1').content;
    },
    massCa2() {
      return this.taskState.getValueBySymbol('massCa2').content;
    },
  },
};
</script>
<style scoped></style>
